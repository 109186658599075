import { Caption1, Flex, H5, H6 } from '@components/atoms'
import React, { useEffect, useRef, useState } from 'react'
import { colors } from '@colors/'
import styled from 'styled-components'
import {
  InPlamListItem,
  SegmentedControl,
  TabControl,
} from '@components/molecules'
import {
  move_down_gray_img,
  last_page_img,
  no_last_page_img,
  first_page_img,
  no_first_page_img,
} from '@images/'
import Pagination from 'react-js-pagination'
import {
  edit_img_black,
  fluent_megaphone_12_filled,
  pen_fill_24,
} from '@images/index'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'
import HomeInPlamListItem from '@components/molecules/box/HomeInPlamListItem'
import { pixelTrack } from '@utils/facebook/pixel'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import HomeEventBannerList from './HomeEventBannerList'
import ProgressingRewardList from './ProgressingRewardList'

const HomeArticleList = ({
  style,
  offset,
  handlePagination,
  articleList,
  articleListCount,
  sortBy,
  handleChangeSortBy,
  categoryList,
  activeTab,
  handleChangeActiveTap,
  artistInfo,
  handleMoveDetail,
  history,
  needAuth,
  currentUser,
  contentRef,
  bannerList,
  rewardList,
  fetchRewardList,
  setShowDownloadPopup,
  notice,
}) => {
  const [categoryIndex, setCategoryIndex] = useState(0)

  const countOffset = () => {
    handlePagination(offset + 1)
  }

  const categoryData = [
    {
      label: '핫스팟',
      value: 'popular',
      ref: useRef(),
    },
    {
      label: '자유스팟',
      value: 'free',
      ref: useRef(),
    },
    {
      label: '정보/소식',
      value: 'info',
      ref: useRef(),
    },
    {
      label: '공지사항',
      value: 'notice',
      ref: useRef(),
    },
  ]

  const sortByData = [
    {
      label: '최신순',
      value: 'createdAt',
      ref: useRef(),
    },
    {
      label: '인기순',
      value: 'popularity',
      ref: useRef(),
    },
  ]

  return (
    <InfiniteScroll
      dataLength={articleList.length}
      scrollThreshold={0.8}
      next={countOffset}
      hasMore={articleListCount > articleList.length}
      loader={
        <ReactLoading
          type="spin"
          color="black"
          style={
            {
              width: '50px',
              height: '50px',
              margin: '30px auto 50px auto',
            }
          }
        />
      }
      scrollableTarget={contentRef}
      style={{ overflow: 'auto' }}
    >
      <Flex
        type={'column'}
        style={{ position: 'sticky', top: '52px', zIndex: 50 }}
      >
        <Flex
          type={'column'}
          style={
            {
              marginBottom: '12px',
            }
          }
        >
          <TabControl
            name="category"
            callback={
              val => {
                handleChangeActiveTap(val)
              }
            }
            defaultIndex={categoryIndex}
            segments={categoryData}
          />
        </Flex>
      </Flex>
      {
        activeTab !== 'popular' && activeTab !== 'notice' && (
          <Flex
            type={'column'}
            style={{ padding: '0 20px', marginBottom: '16px' }}
          >
            <SegmentedControl
              name="sortBy"
              callback={
                val => {
                  handleChangeSortBy(val)
                }
              }
              defaultIndex={sortByData?.findIndex(val => val.value === sortBy)}
              segments={sortByData}
            />
          </Flex>
        )
      }
      {
        activeTab === 'popular' && !!notice?.[0] && (
          <Flex
            align={'center'}
            style={
              {
                padding: '10px 8px',
                borderRadius: '4px',
                background: '#f7f7f7',
                cursor: 'pointer',
                margin: '0 20px',
                marginBottom: '18px',
              }
            }
            onClick={
              () => {
                pixelTrack(PIXEL_EVENTS.홈.공지, {})
                history.push(
                  `/fanspot/total/${notice?.[0]?._id}?offset=0&sortBy=createdAt`,
                )
              }
            }
          >
            <img
              src={fluent_megaphone_12_filled}
              style={{ width: '12px', height: '12px', marginRight: '6px' }}
            />

            <NoticeText type={'Regular'} style={{}}>
              {notice?.[0]?.title}
            </NoticeText>
          </Flex>
        )
      }
      <Flex type={'column'} style={{ gap: '24px', padding: '0 20px' }}>
        {
          articleList
          && articleList.map((item, index) => {
            return (
              <>
                <HomeInPlamListItem
                  item={item}
                  key={item._id}
                  onClick={handleMoveDetail}
                  category={activeTab}
                />
                {
                  ((articleListCount < 3 && index === articleList?.length - 1)
                  || index === 2
                  || index % 10 === 2)
                  && rewardList?.length > 0 && (
                    <ProgressingRewardList
                      progressingRewardList={rewardList}
                      fetchRewardList={fetchRewardList}
                      setShowDownloadPopup={setShowDownloadPopup}
                      showIndex={index / 10}
                    />
                  )
                }

                {
                  ((articleListCount < 7 && index === articleList?.length - 1)
                  || index === 6)
                  && bannerList?.length > 0 && (
                    <HomeEventBannerList
                      bannerList={bannerList}
                      setShowDownloadPopup={setShowDownloadPopup}
                    />
                  )
                }
              </>
            )
          })
        }
      </Flex>
    </InfiniteScroll>
  )
}

export default HomeArticleList

const NoticeText = styled(Caption1)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`
