import {
  AppDownloadPopup,
  BurgerMenu,
  FanspotListBottomModal,
  HomeEventBannerList,
  LoginPopup,
  NoticePopup,
  ProgressingRewardList,
} from '@components/organisms'
import { BodyWrapper, Caption1, Flex } from '@components/atoms'

import { Observer, observer } from 'mobx-react'
import { getIsWebApp } from '@utils/postMessage'
import React, { useEffect, useRef, useState } from 'react'

import LikeArtistList from '@components/organisms/List/LikeArtistList'
import { useScrollRestoration, useStore } from '@utils/hooks'
import HomeArticleList from '@components/organisms/List/HomeArticleList'
import styled from 'styled-components'
import { fluent_megaphone_12_filled, white_pen_fill_24px } from '@images/index'
import HomeInPlamListItem from '@components/molecules/box/HomeInPlamListItem'
import { pixelTrack } from '@utils/facebook/pixel'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { SegmentedControl, TabControl } from '@components/molecules'
import InfiniteScroll from 'react-infinite-scroll-component'
import ReactLoading from 'react-loading'

const HomeTemplate = ({
  noticePopupList,
  currentUser,
  rewardList,
  fetchRewardList,
  articleList,
  articleListCount,
  myFanSpotList,
  history,
  bannerList,
  notice,
  handlePagination,
  offset,
  handleChangeSortBy,
  sortBy,
  handleChangeActiveTap,
  activeTab,
  contentRef,
}) => {
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false) // 로그인 상태
  const [isOpen, setIsOpen] = useState(false) // 햄버거

  const [showDownloadPopup, setShowDownloadPopup] = useState(false) // 앱 다운로드 팝업
  const [showNoticePopup, setShowNoticePopup] = useState(false) // 공지사항 팝업
  const { authStore, loadingStore, articleStore } = useStore()
  const { isInitialLoading, isLoading } = loadingStore

  const [isFanSpotModalOpen, setIsFanSpotModalOpen] = useState(false)

  const parentRef = useRef(null)
  const [parentOffset, setParentOffset] = useState({ left: 0, width: 0 })

  useEffect(() => {
    const updateOffset = () => {
      if (parentRef.current) {
        const rect = parentRef.current.getBoundingClientRect()

        setParentOffset(rect)
      }
    }

    // 초기 위치 설정
    updateOffset()

    // 창 크기 변경 시 위치 업데이트
    window.addEventListener('resize', updateOffset)
    return () => {
      window.removeEventListener('resize', updateOffset)
    }
  }, [])

  const handleMoveDetail = id => {
    if (!id) return
    history.push({
      pathname: `/fanspot/total/${id}`,
      search: `?offset=${offset}&sortBy=${sortBy}`,
      state: { fromActivity: 'all' },
    })
  }

  useEffect(() => {
    const isWebApp = getIsWebApp({ navigator })

    if (noticePopupList?.length > 0 && !isWebApp) {
      setShowNoticePopup(true)
    }
  }, [noticePopupList])

  const countOffset = () => {
    handlePagination(offset + 1)
  }

  const categoryData = [
    {
      label: '핫스팟',
      value: 'popular',
      ref: useRef(),
    },
    {
      label: '자유스팟',
      value: 'free',
      ref: useRef(),
    },
    {
      label: '정보/소식',
      value: 'info',
      ref: useRef(),
    },
    {
      label: '공지사항',
      value: 'notice',
      ref: useRef(),
    },
  ]

  const sortByData = [
    {
      label: '최신순',
      value: 'createdAt',
      ref: useRef(),
    },
    {
      label: '인기순',
      value: 'popularity',
      ref: useRef(),
    },
  ]

  useScrollRestoration(contentRef)

  useEffect(() => {
    const handleScroll = async () => {
      if (
        contentRef.current
        && contentRef.current.scrollTop + contentRef.current.clientHeight
          >= contentRef.current.scrollHeight - 200
        && !isLoading
      ) {
        countOffset()
      }
    }

    const scroller = contentRef.current
    if (scroller) {
      scroller.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scroller) {
        scroller.removeEventListener('scroll', handleScroll)
      }
    }
  }, [isLoading])

  return (
    <div ref={contentRef} style={{ height: '100vh', overflow: 'auto' }}>
      <BurgerMenu isHome isOpen={isOpen} setIsOpen={setIsOpen} />
      <BodyWrapper
        ref={parentRef}
        style={
          {
            background: '#fff',
            padding: '56px 0',
            overflow: 'visible',
          }
        }
      >
        {
          <Observer>
            {
              () => {
                const isMyFanspotList =
                myFanSpotList?.findIndex(val => val?.isInplamFavorite) > -1

                const dataList = myFanSpotList

                if (myFanSpotList?.length > 0 || isInitialLoading) {
                  return (
                    <LikeArtistList
                      isMyFanspotList={isMyFanspotList}
                      list={dataList}
                      isLoading={isInitialLoading}
                    />
                  )
                }
                return null
              }
            }
          </Observer>
        }
        <Flex
          type={'column'}
          style={{ position: 'sticky', top: '52px', zIndex: 50 }}
        >
          <Flex
            type={'column'}
            style={
              {
                marginBottom: '12px',
              }
            }
          >
            <TabControl
              name="category"
              callback={
                val => {
                  handleChangeActiveTap(val)
                }
              }
              defaultIndex={
                categoryData?.findIndex(val => val.value === activeTab) === -1
                  ? 0
                  : categoryData?.findIndex(val => val.value === activeTab)
              }
              segments={categoryData}
            />
          </Flex>
        </Flex>
        {
          activeTab !== 'popular' && activeTab !== 'notice' && (
            <Flex
              type={'column'}
              style={{ padding: '0 20px', marginBottom: '16px' }}
            >
              <SegmentedControl
                name="sortBy"
                callback={
                  val => {
                    handleChangeSortBy(val)
                  }
                }
                defaultIndex={sortByData?.findIndex(val => val.value === sortBy)}
                segments={sortByData}
              />
            </Flex>
          )
        }
        {
          activeTab === 'popular' && !!notice?.[0] && (
            <Flex
              align={'center'}
              style={
                {
                  padding: '10px 8px',
                  borderRadius: '4px',
                  background: '#f7f7f7',
                  cursor: 'pointer',
                  margin: '0 20px',
                  marginBottom: '18px',
                }
              }
              onClick={
                () => {
                  pixelTrack(PIXEL_EVENTS.홈.공지, {})
                  history.push(
                    `/fanspot/total/${notice?.[0]?._id}?offset=0&sortBy=createdAt`,
                  )
                }
              }
            >
              <img
                src={fluent_megaphone_12_filled}
                style={{ width: '12px', height: '12px', marginRight: '6px' }}
              />

              <NoticeText type={'Regular'} style={{}}>
                {notice?.[0]?.title}
              </NoticeText>
            </Flex>
          )
        }
        <Flex type={'column'} style={{ gap: '24px', padding: '0 20px' }}>
          {
            articleList
            && articleList.map((item, index) => {
              return (
                <>
                  <HomeInPlamListItem
                    item={item}
                    key={item._id}
                    onClick={handleMoveDetail}
                    category={activeTab}
                  />
                  {
                    ((articleListCount < 3
                    && index === articleList?.length - 1)
                    || index === 2
                    || index % 10 === 2)
                    && rewardList?.length > 0 && (
                      <ProgressingRewardList
                        progressingRewardList={rewardList}
                        fetchRewardList={fetchRewardList}
                        setShowDownloadPopup={setShowDownloadPopup}
                        showIndex={index / 10}
                      />
                    )
                  }

                  {
                    ((articleListCount < 7
                    && index === articleList?.length - 1)
                    || index === 6)
                    && bannerList?.length > 0 && (
                      <HomeEventBannerList
                        bannerList={bannerList}
                        setShowDownloadPopup={setShowDownloadPopup}
                      />
                    )
                  }
                </>
              )
            })
          }
        </Flex>
      </BodyWrapper>

      {
        isShowLoginPopup && (
          <LoginPopup handleClose={() => setIsShowLoginPopup(false)} />
        )
      }
      {
        showDownloadPopup && (
          <AppDownloadPopup handleClose={() => setShowDownloadPopup(false)} />
        )
      }
      {
        showNoticePopup && (
          <NoticePopup
            noticePopupList={noticePopupList}
            handleClose={() => setShowNoticePopup(false)}
            type={'notice'}
          />
        )
      }
      <FanspotListBottomModal
        isModalOpen={isFanSpotModalOpen}
        setIsFanSpotModalOpen={setIsFanSpotModalOpen}
        history={history}
        isHome
      />

      {
        <FloatingButton
          style={
            {
              right: parentOffset.x,
              bottom: '32px',
              pointerEvents: 'auto',
              cursor: 'pointer',
            }
          }
          onClick={
            () => {
              if (!(currentUser && currentUser._id)) {
                setIsShowLoginPopup(true)
                return
              }

              setIsFanSpotModalOpen(true)
            }
          }
          isShowFloatingButton
        >
          <FloatingImg src={white_pen_fill_24px} alt={'inplamAdd-float'} />
        </FloatingButton>
      }
    </div>
  )
}

export default observer(HomeTemplate)

const FloatingButton = styled(Flex)`
  position: fixed;
  z-index: 500;
  transform: translateX(-50%); /* 부모 기준 중앙 정렬 */
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: var(--color-black-solid, #ff5722);
  box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.08);
  opacity: ${props => props.isShowFloatingButton};
  transition: opacity 0.3s ease;
`
const FloatingImg = styled.img`
  filter: invert(0);
  width: 24px;
  height: 24px;
`
const NoticeText = styled(Caption1)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
`
