import { Caption1, Caption2, Flex, H6 } from '@components/atoms'
import React, { useEffect, useRef } from 'react'
import { converseCommentUnitEng, convertToPricingComma } from '@utils/format'
import {
  sns_instagram,
  sns_twitter,
  sns_youtube,
  thumbnail_img,
  view_fill_24px,
} from '@images/index'

import { Image } from '@components/molecules'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { colors } from '@colors/'
import { fan_score_img } from '@images'
import { pixelTrack } from '@utils/facebook/pixel'
import qs from 'qs'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useObserver } from 'mobx-react'
import { useStore } from '@utils/hooks'

const SnsIconComponenet = ({ text }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  if (!text) {
    return null
  }

  const icons = text.split(urlRegex).map(url => {
    const query = qs.parse(url, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })

    if (url.indexOf('youtube') !== -1) {
      // if (url.indexOf('youtube') !== -1) {
      if (query[Object.keys(query)[0]]) {
        return <SnsImg src={sns_youtube} />
      }
    }
    if (url.indexOf('youtu.be') !== -1) {
      // if (!query[Object.keys(query)[0]] && url.indexOf('youtu.be') !== -1) {

      return <SnsImg src={sns_youtube} />
    }

    // 인스타그램 링크 처리 (임베드 공유 복사)
    if (
      url.includes('instagram.com/')
      && url.includes('?utm_source=ig_embed')
    ) {
      return <SnsImg src={sns_instagram} />
    }

    // 인스타그램 링크 처리 (임베드 링크 복사)
    if (
      url.includes('instagram.com/p/')
      || url.includes('instagram.com/reel/')
    ) {
      return <SnsImg src={sns_instagram} />
    }

    // X (구 Twitter) 링크 처리
    if (
      url.includes('twitter.com')
      || (url.includes('x.com') && url.includes('?ref_src=twsrc'))
    ) {
      return <SnsImg src={sns_twitter} />
    }

    // X (구 Twitter) 링크 처리
    if (url.includes('twitter.com') || url.includes('x.com')) {
      return <SnsImg src={sns_twitter} />
    }

    return null
  })

  return icons.filter(val => !!val)?.[0] || null
}

const ListItem = ({ item, onClick }) => {
  return (
    <InPlamListItem type={'column'} onClick={onClick}>
      <Flex style={{ width: '100%', height: '24px' }}>
        <Flex align={'center'} sju style={{ flex: 1 }}>
          <TitleText style={{}}>{item.title}</TitleText>
          {
item?.image480PathList?.length > 0 && (
              <ThumbnailImg src={thumbnail_img} />
            )
          }
          <SnsIconComponenet text={item?.text} />
        </Flex>
        <Flex align={'center'}>
          <Image src={view_fill_24px} size={['16px', '16px']} />
          <ViewCountText>
            {item.viewCount && converseCommentUnitEng(item.viewCount)}
          </ViewCountText>
        </Flex>
      </Flex>
      <BodyText type={'Regular'}>{item.text}</BodyText>
    </InPlamListItem>
  )
}

const ArtistInPlamList = ({ uniqueName, artistId, fanSpotPoint = 0 }) => {
  const { articleStore } = useStore()

  const history = useHistory()

  const listScrollInterval = useRef(null) // 스크롤 애니메이션 인터벌 아이디
  const listRef = useRef(null) // 스크롤 element 아이디

  // 자동 전환 스크롤 로직
  const autoScrollInPlam = () => {
    if (
      articleStore.artistArticleList
      && articleStore.artistArticleList.length < 1
    ) {
      return
    }
    listScrollInterval.current = setInterval(() => {
      listRef
        && listRef.current
        && listRef.current.scrollTo({
          top: listRef.current.scrollTop + 30,
          behavior: 'smooth',
        })

      if (
        listRef.current
        && listRef.current.scrollTop === listRef.current.scrollHeight - 90
      ) {
        listRef.current.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }, 2000)
  }

  useEffect(() => {
    articleStore.fetchArtistArticleList({
      uniqueName,
      // writerType: 'artist',
      sortBy: 'createdAt',
      limit: 2,
    })
  }, [uniqueName])

  useEffect(() => {
    if (articleStore.artistArticleList) {
      autoScrollInPlam()
    }

    // clean up
    return () => {
      clearInterval(listScrollInterval.current)
    }
  }, [articleStore.artistArticleList])

  return useObserver(() => (
    <ListBox>
      <Flex
        align="center"
        justify="space-between"
        style={
          {
            marginBottom: articleStore?.artistArticleList?.length > 0 ? 12 : 0,
            cursor: 'pointer',
          }
        }
        onClick={
          () => {
            history.push(`/fanspot/${uniqueName}`)
            pixelTrack(PIXEL_EVENTS?.아티스트?.팬스팟, {
              artistId,
              uniqueName,
            })
          }
        }
      >
        <Flex>
          <H6 type="Bold" align="left">
            {`팬스팟`}
          </H6>
          {
            fanSpotPoint >= 10000 && (
              <Flex
                style={
                  {
                    borderRadius: '100px',
                    background: '#FF5722',
                    padding: '4px 8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '8px',
                  }
                }
              >
                <img
                  src={fan_score_img}
                  width={12}
                  height={12}
                  alt={'fanscore'}
                />
                <Caption2
                  type={'Regular'}
                  color="#ffffff"
                  style={{ marginLeft: '2px' }}
                >
                  {`스코어`}
                </Caption2>
                <Caption2
                  type={'Regular'}
                  color="#ffffff"
                  style={{ marginLeft: '6px' }}
                >
                  {`${convertToPricingComma(fanSpotPoint)}`}
                </Caption2>
              </Flex>
            )
          }
        </Flex>
        {
          articleStore.artistArticleListTotalCount < 1 ? (
            <Caption1
              color={colors.brown_grey}
              style={{ cursor: 'pointer' }}
              onClick={
                event => {
                  event.stopPropagation()

                  history.push(`/fanspot/add/${uniqueName}/${artistId}`)
                  pixelTrack(PIXEL_EVENTS?.아티스트?.팬스팟_첫글, {
                    artistId,
                    uniqueName,
                  })
                }
              }
            >
            첫 글 작성하기
            </Caption1>
          ) : (
            <Caption1 color={colors.brown_grey} style={{ cursor: 'pointer' }}>
            더보기
            </Caption1>
          )
        }
      </Flex>
      {
        articleStore.artistArticleList
        && articleStore.artistArticleList.length > 0 && (
          <InPlamList
            ref={listRef}
            onMouseEnter={() => clearInterval(listScrollInterval.current)}
            onMouseLeave={() => autoScrollInPlam()}
          >
            {
              articleStore.artistArticleList.map(item => (
                <ListItem
                  item={item}
                  key={item._id}
                  onClick={
                    () => {
                      history.push(
                        `/fanspot/${uniqueName}/${item._id}?offset=0&sortBy=createdAt`,
                      )
                      pixelTrack(PIXEL_EVENTS?.아티스트?.팬스팟_게시글, {
                        artistId,
                        uniqueName,
                        articleId: item._id,
                      })
                    }
                  }
                />
              ))
            }
          </InPlamList>
        )
      }
    </ListBox>
  ))
}

export default ArtistInPlamList

const ListBox = styled(Flex)`
  margin: 10px 0px;
  flex-direction: column;
  padding: 14px 16px;
  border-radius: 10px;
  border: 1px solid var(--grey-grey_30, #f4f4f4);
`

const InPlamList = styled(Flex)`
  flex-direction: column;
  /* height: 90px; */
  height: fit-content;
  /* max-height: 90px; */
  overflow: auto;
  gap: 20px;
`

const InPlamListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  min-height: 30px;
  align-items: center;
  box-sizing: border-box;
`

const TitleText = styled(H6)`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-right: 4px;
`
const BodyText = styled(H6)`
  width: 100%;
  height: 24px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  white-space: pre-wrap;
`

const ViewCountText = styled(Caption1)`
  margin-left: 4px;
  box-sizing: border-box;
  color: #646464;
`
const ThumbnailImg = styled.img`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  object-fit: cover;
  margin-left: 4px;
`

const SnsImg = styled.img`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  object-fit: cover;
  margin-left: 4px;
`
