import { Flex, Typography } from '@components/atoms'
import { Image, LikeBtn, SharedBtn } from '@components/molecules'
import React, { useState } from 'react'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import { DOMAIN_URL } from '@consts/'
import { Link } from 'react-router-dom'
import { LinkCopiedPopup } from '@components/organisms'
import { PIXEL_EVENTS } from '@utils/facebook/pixelLogs'
import { default_album_img } from '@images'
import { handleAlbumImgError } from '@utils/handler'
import { pixelTrack } from '@utils/facebook/pixel'

const ellipsisStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  wordWrap: 'break-word',
  width: '100%',
}

const TrackCard = ({
  albumImg,
  title,
  artist,
  albumTitle,
  currentPath,
  albumId,
  uniqueName,
  likeInfo,
  updateLike,
  trackId,
  currentUser,
  setIsShowLoginPopup,
  setShowLikePopup,
  subdomainInfo,
  customUrlInfo,
  albumCustomUrlInfo,
  setIsDetailCover,
}) => {
  const [isCopied, setIsCopied] = useState(false) // 링크 복사 유무

  const linkCopy = () => {
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2500)
    pixelTrack(PIXEL_EVENTS.트랙.공유, {
      trackId,
      trackTitle: title,
      artistId: artist[0]._id,
      artistName: artist[0].name,
    })
  }

  const handleLike = () => {
    if (currentUser) {
      if (likeInfo.isLike) {
        updateLike('delete', currentUser._id, trackId, 'track')
      }
      else {
        updateLike('add', currentUser._id, trackId, 'track')
        setShowLikePopup(true)
      }
    }
    else {
      setIsShowLoginPopup(true)
    }

    pixelTrack(PIXEL_EVENTS.트랙.좋아요, {
      trackId,
      trackTitle: title,
      artistId: artist[0]._id,
      artistName: artist[0].name,
    })
  }

  return (
    // 음악 카드
    <>
      <Flex
        style={
          {
            padding: '46px 0 10px 0',
            justifyContent: 'space-between',
            zIndex: 1,
          }
        }
      >
        <Flex>
          <Image
            src={albumImg ? albumImg : default_album_img}
            size={['116px', '116px']}
            style={
              {
                borderRadius: '6px',
              }
            }
            onError={handleAlbumImgError}
            onClick={() => setIsDetailCover(true)}
          />
          <Flex type="column" style={{ paddingLeft: '16px' }}>
            <Typography
              color="#ffffff"
              type="Medium"
              size="16px"
              style={ellipsisStyle}
            >
              {title}
            </Typography>
            <Typography
              type="Medium"
              size="12px"
              color="#ffffff"
              style={{ ...ellipsisStyle, ...{ margin: '2px 0 4px 0' } }}
            >
              {
                artist
                && artist.map((a, index) =>
                  a.isAuto ? (
                    <span key={a._id}>
                      {a.name}
                      {!(index === artist.length - 1) && ',\u00A0'}
                    </span>
                  ) : (
                    <Link
                      style={
                        {
                          color: '#ffffff',
                          fontFamily: 'Pretendard-Bold',
                        }
                      }
                      to={`/artist/${a.uniqueName}`}
                      key={a._id}
                      onClick={
                        () =>
                          pixelTrack(PIXEL_EVENTS.트랙.아티스트링크1, {
                            trackId,
                            trackTitle: title,
                            artistId: a._id,
                            artistName: a.name,
                          })
                      }
                    >
                      {a.name}
                      {!(index === artist.length - 1) && ',\u00A0'}
                    </Link>
                  ),
                )
              }
            </Typography>
            {/* <Link to={'/album/' + albumId}> */}
            <Link
              to={
                `/album/${
                  albumCustomUrlInfo && albumCustomUrlInfo.autoUrl
                    ? albumCustomUrlInfo.autoUrl
                    : albumId
                }`
              }
              onClick={
                () =>
                  pixelTrack(PIXEL_EVENTS.트랙.앨범링크, {
                    albumId,
                    albumTitle,
                    trackId,
                    trackTitle: title,
                    artistId: artist[0]._id,
                    artistName: artist[0].name,
                  })
              }
            >
              <Typography size="10px" color="#ffffff" style={ellipsisStyle}>
                {albumTitle}
              </Typography>
            </Link>
          </Flex>
        </Flex>
        <Flex
          type="column"
          style={
            {
              justifyContent: 'space-between',
            }
          }
        >
          <CopyToClipboard
            text={
              subdomainInfo
              && subdomainInfo.subdomain
              && subdomainInfo.isAccepted
                ? customUrlInfo && customUrlInfo.customUrl
                  ? `${subdomainInfo.subdomain}.${DOMAIN_URL}/${customUrlInfo
                      && customUrlInfo.customUrl}`
                  : `${subdomainInfo.subdomain}.${DOMAIN_URL}/${customUrlInfo
                      && customUrlInfo.autoUrl}`
                : currentPath
            }
            onCopy={() => linkCopy()}
          >
            <SharedBtn />
          </CopyToClipboard>
          <LikeBtn
            active={likeInfo && likeInfo.isLike}
            count={likeInfo && likeInfo.likeCount}
            onClick={() => handleLike()}
          />
        </Flex>
      </Flex>
      {isCopied && <LinkCopiedPopup />}
    </>
  )
}

export default TrackCard
