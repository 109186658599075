import React, { useState } from 'react'
import { Flex, BodyWrapper, Caption1, H6 } from '@components/atoms'
import {
  LoginPopup,
  BurgerMenu,
  InPlamArticleCommentList,
  OkCancelPopup,
  useSnackBar,
  ReportPopup,
  BlockPopup,
  ReferralCopiedPopup,
  FanspotLinkCopiedPopup,
  FavoritePopup,
} from '@components/organisms'
import styled, { css } from 'styled-components'
import { colors } from '@colors/'
import { converseCommentUnitEng, dateTodayYMDMM } from '@utils/format'
import { observer } from 'mobx-react'
import {
  trash_img_red,
  edit_img_black,
  notice_img,
  auth_artist_img,
  article_list_img,
  clip_img,
} from '@images/'
import { useHistory } from 'react-router-dom'
import {
  DetailCoverImage,
  Image,
  InPlamListItem,
  YoutubeInText,
} from '@components/molecules'
import { Helmet } from 'react-helmet'
import CopyToClipboard from 'react-copy-to-clipboard'
import { DOMAIN_URL, STORAGE_URL } from '@consts/'
import {
  default_profile_img,
  plamin_circle_logo_img,
  share_img_black,
  thumbup_fill_24_on,
  thumbup_fill_24_on_white,
} from '@images/index'

const InPlamArticleTemplate = ({
  handleArticleLike,
  artistInfo,
  article,
  currentUser,
  isLike,
  artistIsLike,
  removeArticle,
  showLoginPopup,
  setShowLoginPopup,
  nextArticleList,
  handleMoveDetail,
  handleMoveList,
  query,
  reportArticle,
  blockUser,
  inplamFavoritesInfo,
  addFavoritesInplam,
}) => {
  const [isOpen, setIsOpen] = useState(false) // 햄버거
  const [detailCover, setDetailCover] = useState(false) // 이미지 상세

  const [isCopiedLink, setIsCopiedLink] = useState(false) // 팬스팟 링크 복사 유무

  const history = useHistory()

  const {
    isShowing: deleteShowing,
    toggle: deleteToggle,
  } = OkCancelPopup.usePopup()

  const {
    isShowing: reportShowing,
    toggle: reportToggle,
  } = ReportPopup.usePopup()

  const {
    isShowing: favoriteShowing,
    toggle: favoriteToggle,
  } = FavoritePopup.usePopup()

  const { isShowing: blockShowing, toggle: blockToggle } = BlockPopup.usePopup()

  const {
    toggleSnackBar: toggleDuplicateReport,
    View: DuplicateReportSnackBar,
  } = useSnackBar()
  const { toggleSnackBar: toggleReport, View: ReportSnackBar } = useSnackBar()

  const _reportArticle = async reason => {
    if (!currentUser) {
      setShowLoginPopup(true)
      return
    }
    const reported = await reportArticle({
      articleId: article._id,
      reason,
    })
    if (reported === 'completed') {
      // alert('신고가 접수되었습니다.')
      toggleReport()
    }
    else if (reported === 'conflicted') {
      // alert('이미 신고하신 댓글입니다.')
      toggleDuplicateReport()
    }
  }
  const {
    toggleSnackBar: toggleFailedBlock,
    View: FailedBlockSnackBar,
  } = useSnackBar()
  const { toggleSnackBar: toggleBlock, View: BlockSnackBar } = useSnackBar()

  const _addFavorite = async () => {
    if (!currentUser) {
      setShowLoginPopup(true)
      return
    }

    const addFavorites = await addFavoritesInplam(artistInfo?._id)

    if (addFavorites === 'completed') {
      favoriteToggle()
    }
  }

  const _blockUser = async () => {
    if (!currentUser) {
      setShowLoginPopup(true)
      return
    }

    const blocked = await blockUser({
      blockedUserId: article.writerId,
      isAnonymous: article.isAnonymous,
    })
    if (blocked === 'completed') {
      // alert('신고가 접수되었습니다.')
      handleMoveList()
      toggleBlock()
    }
    else if (blocked === 'error') {
      // alert('이미 신고하신 댓글입니다.')
      toggleFailedBlock()
    }
  }

  const _removeArticle = async () => {
    if (!currentUser) return
    const deleted = await removeArticle(article._id, currentUser._id)
    if (deleted) history.push(`/fanspot/${artistInfo.uniqueName}`)
  }

  const linkCopy = () => {
    setIsCopiedLink(true)

    setTimeout(() => {
      setIsCopiedLink(false)
    }, 2500)
  }

  const checkFavorite = async func => {
    if (inplamFavoritesInfo) {
      await func()
    }
    else {
      favoriteToggle()
    }
  }

  if (article) {
    return (
      <>
        <Helmet>
          <title>
            {
              `(${
                article && article.isNotice
                  ? '공지사항'
                  : artistInfo && artistInfo.name
              }) 팬스팟`
            }
          </title>
          <meta
            name="description"
            content="나의 소중한 아티스트와 소통하는 공간, 팬스팟"
          />
        </Helmet>
        <BurgerMenu
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          pageWrapId={'comment_template'}
          outerContainerId={'App'}
          pageTitle={
            article && article.isNotice
              ? '공지사항'
              : artistInfo && artistInfo.name
          }
          pageId={
            article?.isNotice
              ? 'total?filterBy=notice'
              : artistInfo && artistInfo.uniqueName
          }
          pageType={
            article?.isNotice || artistInfo?.uniqueName === 'free-spot'
              ? 'fanspot'
              : 'artist'
          }
        />
        <BodyWrapper id="comment_template">
          <ArticleBox>
            <Flex justify="space-between">
              <Flex
                align="center"
                style={{ cursor: 'pointer' }}
                onClick={handleMoveList}
              >
                <img
                  src={article_list_img}
                  alt="list_icon"
                  style={{ width: 20, height: 20, marginRight: 4 }}
                />
                <Caption1>목록</Caption1>
              </Flex>
              {
                !article.isNotice && (
                  <Flex align="center">
                    {
                      article.isMine && (
                        <>
                          <Caption1
                            onClick={deleteToggle}
                            color={colors.lightish_red}
                            style={{ cursor: 'pointer' }}
                          >
                        삭제
                          </Caption1>
                          <Caption1
                            style={{ cursor: 'pointer', margin: '0 16px' }}
                            onClick={
                              () =>
                                history.push({
                                  pathname: `/fanspot/add/${artistInfo.uniqueName}/${artistInfo._id}`,
                                  state: {
                                    isUpdate: true,
                                  },
                                })
                            }
                          >
                        수정
                          </Caption1>
                        </>
                      )
                    }
                    {
                      <Flex>
                        {
                          !article.isNotice && !article.isMine && (
                            <Caption1
                              onClick={
                                () => {
                                  checkFavorite(blockToggle)
                                }
                              }
                              color={colors.black_two}
                              style={
                                {
                                  cursor: 'pointer',
                                  marginRight: '16px',
                                }
                              }
                            >
                          차단
                            </Caption1>
                          )
                        }
                        {
                          !article.isNotice && !article.isMine && (
                            <Caption1
                              onClick={
                                () => {
                                  checkFavorite(reportToggle)
                                }
                              }
                              color={colors.black_two}
                              style={
                                {
                                  cursor: 'pointer',
                                }
                              }
                            >
                          신고
                            </Caption1>
                          )
                        }
                      </Flex>
                    }
                  </Flex>
                )
              }
            </Flex>
            <Br style={{ margin: '16px 0px 16px -20px' }} />
            <Flex align="center">
              {
                article.isNotice && article?.isOfficial && (
                  <FromArtistImg src={auth_artist_img} alt="from_artist_icon" />
                )
              }
              {
                article.isNotice && (
                  <img
                    src={notice_img}
                    style={{ width: 32, height: 16, marginRight: 8 }}
                    alt="notice_icon"
                  />
                )
              }
              <H6 align="left" style={{ overflowWrap: 'anywhere' }}>
                {article.title}
              </H6>
            </Flex>
            <Flex style={{ margin: '8px 0 16px 0' }} justify="space-between">
              <Flex align={'center'}>
                <Image
                  src={
                    article?.isNotice
                      ? plamin_circle_logo_img
                      : article?.writerInfo?.profileImage64Path
                        ? `${STORAGE_URL}${article?.writerInfo?.profileImage64Path}`
                        : default_profile_img
                  }
                  size={['16px', '16px']}
                  style={
                    {
                      marginRight: '4px',
                      borderRadius: '50%',
                      border: `solid 0.5px ${colors.inActive}`,
                    }
                  }
                />
                <Caption1 type="Regular" color={colors.brown_grey}>
                  {
article?.isNotice
  ? '플램 PLAM'
  : article?.isAnonymous
    ? '익명'
    : article.writerId === artistInfo?.userId
      ? artistInfo?.name
      : article.writerInfo && article.writerInfo.nickname
                  }
                </Caption1>
                {
                  !article.isAnonymous && article?.isOfficial && (
                    <FromArtistImg
                      style={{ width: 16, height: 16, marginLeft: '2px' }}
                      src={auth_artist_img}
                      alt="from_artist_icon"
                    />
                  )
                }
                <Caption1
                  type="Regular"
                  color={colors.brown_grey}
                  style={{ marginLeft: 8 }}
                >
                  {dateTodayYMDMM(article.updatedAt)}
                </Caption1>
                <Caption1
                  type="Regular"
                  color={colors.brown_grey}
                  style={{ marginLeft: 8 }}
                >
                  {`조회 ${converseCommentUnitEng(article.viewCount) || 0}`}
                </Caption1>
              </Flex>
            </Flex>
            <Br />
            {
              article.image480PathUriList
              && article.image480PathUriList.length > 0 && (
                <FileList>
                  {
                    article.image480PathUriList.map((url, index) => (
                      // 정사각형 로직
                      // <ImageItem
                      //   key={`${url}`}
                      //   onClick={
                      //     () =>
                      //       setDetailCover(article.imageOriginalPathUriList[index])
                      //   }
                      // >
                      //   <img src={url} alt={`image_${index}`} />
                      // </ImageItem>
                      <ImageItem
                        src={url}
                        alt={`image_${index}`}
                        key={`${url}`}
                        onClick={
                          () =>
                            setDetailCover(article.imageOriginalPathUriList[index])
                        }
                      />
                    ))
                  }
                </FileList>
              )
            }
            {/* <Content value={article.text} readOnly minRows={8} /> */}
            <YoutubeInText text={article.text} />
            <Br style={{ marginBottom: '20px' }} />
            {
              <Flex align={'center'} justify={'center'} style={{ gap: '12px' }}>
                <CopyToClipboard
                  text={`https://${DOMAIN_URL}/fanspot/total/${article._id}?offset=0&sortBy=createdAt`}
                  onCopy={() => linkCopy()}
                >
                  <ShareBtn isNotice={article.isNotice}>
                    <img
                      src={share_img_black}
                      alt="copy_icon"
                      style={{ width: 20, height: 20, marginRight: 6 }}
                    />
                    {`링크 공유`}
                  </ShareBtn>
                </CopyToClipboard>
                {
                  !article.isNotice && (
                    <RecommendBtn onClick={handleArticleLike} isLike={isLike}>
                      <img
                        src={
                          isLike ? thumbup_fill_24_on_white : thumbup_fill_24_on
                        }
                        style={
                          {
                            width: 20,
                            height: 20,
                            marginRight: 4,
                          }
                        }
                        alt={'like'}
                      />
                      {
                        `${(article.likeCount
                      && converseCommentUnitEng(article.likeCount))
                      || 0}`
                      }
                    </RecommendBtn>
                  )
                }
              </Flex>
            }
          </ArticleBox>
          {/* 댓글 */}
          {
            !article.isNotice && (
              <InPlamArticleCommentList
                currentUser={currentUser}
                isLike={artistIsLike}
                dataCollectionName={'articles'}
                dataObjectId={article && article._id}
                writerId={article && article.writerId}
                artistId={artistInfo && artistInfo._id}
              />
            )
          }

          <Br
            style={
              {
                height: 8,
                backgroundColor: colors.whitef4,
                marginTop: 16,
              }
            }
          />
          {/* 다음글  */}
          <Flex type="column">
            <H6
              type="Bold"
              align="left"
              style={{ marginTop: 24, marginBottom: 20 }}
            >
              다음 글
            </H6>
            <Flex type={'column'} style={{ gap: 25 }}>
              {
                nextArticleList
                && nextArticleList.map(item => (
                  <InPlamListItem
                    item={item}
                    key={item._id}
                    artistInfo={artistInfo}
                    onClick={handleMoveDetail}
                    handleArticleLike={handleArticleLike}
                  />
                ))
              }
            </Flex>
          </Flex>
          <MoreBtn onClick={handleMoveList}>목록</MoreBtn>

          <OkCancelPopup.View
            isShowing={deleteShowing}
            toggle={deleteToggle}
            title="내 게시글을 삭제합니다"
            okText="삭제"
            okColor={colors.lightish_red}
            onOk={_removeArticle}
          />
          {
            detailCover && (
              <DetailCoverImage
                setIsDetailCover={setDetailCover}
                imgSrc={detailCover}
              />
            )
          }
          {
            showLoginPopup && (
              <LoginPopup handleClose={() => setShowLoginPopup(false)} />
            )
          }
          <ReportPopup.View
            isShowing={reportShowing}
            toggle={reportToggle}
            okColor={colors.lightish_red}
            onOk={_reportArticle}
          />
          <BlockPopup.View
            isShowing={blockShowing}
            toggle={blockToggle}
            okColor={colors.lightish_red}
            onOk={_blockUser}
          />
          <FavoritePopup.View
            isShowing={favoriteShowing}
            toggle={favoriteToggle}
            // okColor={colors.lightish_red}
            onOk={_addFavorite}
          />
          {isCopiedLink && <FanspotLinkCopiedPopup />}

          <ReportSnackBar text={'신고처리가 완료되었습니다'} />
          <BlockSnackBar text={'사용자 차단이 완료되었습니다'} />
          <DuplicateReportSnackBar text={'이미 신고한 댓글입니다'} />
          <FailedBlockSnackBar text={'차단에 실패하였습니다'} />
        </BodyWrapper>
      </>
    )
  }
  return <></>
}

export default observer(InPlamArticleTemplate)

const ArticleBox = styled(Flex)`
  margin-top: 56px;
  flex-direction: column;
`

const Br = styled(Flex)`
  margin-left: -20px;
  width: calc(100% + 40px);
  height: 1px;
  border-bottom: solid 1px ${colors.whitef4};
  position: relative;
`

const RecommendBtn = styled(Flex)`
  background-color: ${colors.white};
  cursor: pointer;
  max-width: 104px;
  min-width: 104px;
  box-sizing: border-box;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Pretendard-Medium;
  border: solid 1px ${colors.light_pink};

  ${props =>
    props.isLike
    && css`
      background-color: ${colors.black};
      color: ${colors.white};
      border: none;
    `}
`
const ShareBtn = styled(Flex)`
  background-color: ${colors.white};
  cursor: pointer;
  max-width: 104px;
  min-width: 104px;
  box-sizing: border-box;
  height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Pretendard-Medium;
  border: solid 1px ${colors.light_pink};

  ${props =>
    props.isNotice
    && css`
      margin-left: 0;
    `}
`

const FileList = styled.ol`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  /* justify-content: space-between; */
  justify-content: flex-start;
  flex-wrap: wrap;

  img {
    margin-bottom: 0;
  }

  /* div:first-child,
  div:last-child {
    margin-bottom: 0;
  } */
`

const ImageItem = styled.img`
  margin: 16px 0px;
  object-fit: contain;
  max-width: 100%;
`

// 정사각형
// const ImageItem = styled.div`
//   position: relative;
//   width: 100%;
//   background-color: ${colors.whitef4};
//   margin: 16px 0px;

//   :after {
//     content: '';
//     display: block;
//     padding-bottom: 100%;
//   }

//   img {
//     /* Take your picture out of the flow */
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     /* Make the picture taking the size of it's parent */
//     right: 0;
//     width: 100%; /* This if for the object-fit */

//     /* thisif for the object-fit */
//     height: 100%;
//     object-fit: none;
//     object-position: center;
//   }
// `

const FromArtistImg = styled.img`
  margin-right: 4px;
  width: 20px;
  height: 20px;
`

const MoreBtn = styled(Flex)`
  margin: 16px 0 24px 0;
  width: 100%;
  height: 32px;
  font-size: 12px;
  font-family: Pretendard-Medium;
  align-items: center;
  justify-content: center;
  border: solid 1px ${colors.black_two};
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
`
