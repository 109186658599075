import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollRestoration = scrollRef => {
  const location = useLocation()

  useEffect(() => {
    if (!scrollRef?.current) return

    // 스크롤 위치 저장
    const saveScrollPosition = () => {
      sessionStorage.setItem(
        `scrollPosition-${location.pathname}`,
        scrollRef.current.scrollTop,
      )
    }

    // 페이지를 떠날 때 스크롤 위치 저장
    scrollRef.current.addEventListener('scroll', saveScrollPosition)
    window.addEventListener('beforeunload', saveScrollPosition)

    return () => {
      scrollRef.current?.removeEventListener('scroll', saveScrollPosition)
      window.removeEventListener('beforeunload', saveScrollPosition)
    }
  }, [location, scrollRef])

  useEffect(() => {
    if (!scrollRef?.current) return

    // 저장된 스크롤 위치 복원
    const savedPosition = sessionStorage.getItem(
      `scrollPosition-${location.pathname}`,
    )
    if (savedPosition !== null) {
      scrollRef.current.scrollTo(0, parseInt(savedPosition, 10) - 151)

      sessionStorage.removeItem(`scrollPosition-${location.pathname}`)
    }
  }, [location, scrollRef])
}

export default useScrollRestoration
