export const twitterTrack = (event, data) => {
  try {
    console.log('twitterTrack 호출:', event, data)
    if (typeof window.twq === 'function') {
      window.twq('track', event, data)
    } else {
      console.warn('Twitter 픽셀 스크립트가 아직 로드되지 않았습니다.')
    }
  } catch (error) {
    console.error('twitterTrack 에러:', error)
  }
}
