import React from 'react'
import styled from 'styled-components'
import { Flex } from '@components/atoms'

const Content = styled(Flex)`
  flex-direction: column;
`

const Title = styled.span`
  font-family: Pretendard-Bold;
  font-size: 14px;
  width: 100%;
  margin: 16px 0 17px 0;
`

const List = styled.p`
  font-family: Pretendard-Regular;
  font-size: 12px;
  width: 100%;
  line-height: 1.5;
  margin: 1px 0 8px 0;
`

const TermsOfUseInfo = () => {
  return (
    <Content>
      <Title>제1조 (목적)</Title>
      <List>
        본 약관은 “㈜나이비”(이하 “회사”)가 “플램” 서비스의 이용조건과 운영에
        관한 제반 사항 규정을 목적으로 합니다.
      </List>
      <Title>제 2 조 (약관의 효력과 변경)</Title>
      <List>
        ① 본 약관은 플램을 통하여 이를 공지함으로써 효력이 발생합니다.
        <br />
        ② 플램은 약관의 규제에 관한 법률, 전자거래기본법, 전자서명법,
        정보통신망이용 촉진 및 정보보호이용등에관한법률, 소비자보호법, 저작권법
        등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 약관을
        개정할 경우에는 적용일자 등을 명시하여 현행약관과 함께 “플램”에 그
        적용일자 7일 이전부터 적용일자 전일까지 공지합니다.
        <br />
        ③ 플램은 사정상 중요한 사유가 발생될 경우 사전 고지없이 본 약관의 내용을
        변경할 수 있으며, 변경된 약관은 플램을 통하여 공지합니다.
        <br />
        {' '}
④ 회원은 변경된 약관에 동의하지 않을 경우 회원 탈퇴를 요청할 수
        있으며, 변경된 약관의 효력 발생일 이후 7일 이내에 해지 요청을 하지 않을
        경우 약관의 변경 사항에 동의한 것으로 간주됩니다.
        <br />
        {' '}
⑤ 플램은 회원이 본 약관 내용에 동의하는 것을 조건으로 회원에게
        플램을 제공할 것이며, 회원이 본 약관의 내용에 동의하는 경우, 플램의 정보
        제공에 관련된 모든 행위 및 회원의 플램 사용 행위에는 본 약관이
        우선적으로 적용될 것입니다.
        <br />
        {' '}
⑥ 본 약관에 동의하는 것은 정기적으로 플램에 방문하여 약관의
        변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를
        알지 못해 발생하는 회원의 피해는 플램에서 책임지지 않습니다.
      </List>
      <Title>제 3 조 (약관 외 준칙)</Title>
      <List>
        본 약관에 명시되지 않은 사항은 관계 법령에 규정 되어있을 경우 그 규정에
        따르며, 그렇지 않은 경우에는 일반적인 관례에 따릅니다.
      </List>
      <Title>제 4 조 (용어의 정의)</Title>
      <List>
        본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <br />
        1. 회원 : 본 약관에 따라 회사와 서비스 이용계약을 체결하고 회사가
        제공하는 서비스를 이용하는 개인 또는 기업을 의미합니다.
        <br />
        2. 데이터 : 회원이 서비스를 통하여 보거나, 기고하거나 활동하면서 플램
        내에 생성될 수 있는 텍스트, 소프트웨어, 스크립트, 그래픽, 사진, 이미지
        파일, 사운드, 비디오 및 시청각 혼합 모든 콘텐츠로 개인정보는 제외한다.
        <br />
        3. 아이디(ID) : 회원의 식별 및 서비스 이용을 위하여 회원이 선정하고
        플램이 승인하는 문자와 숫자 혹은 그 조합을 말합니다.
        <br />
        4. 서비스 : 플램이 인터넷상에서 제공하는 모든 서비스
        <br />
        5. 비밀번호 : 아이디(ID)와 일치된 회원임을 확인하고, 회원의 비밀을
        보호하기 위해 회원이 설정한 문자와 숫자의 조합을 의미합니다.
        <br />
        6. 운영자 : 플램의 전반적인 관리와 운영을 담당하는 회사의 직원을 말한다.
        <br />
        7. 리워드 : 제7조에 따라 이용자가 일정한 행위를 마친 때에 시스템에
        무상으로 적립 및 기록되는 서비스 상의 데이터를 말한다.
        <br />
        8. 해지: 회원이 이용계약을 해약하는 것을 말합니다.
      </List>
      <Title>제 5 조 (이용계약의 성립)</Title>
      <List>
        ① 플램의 이용희망자가 가입 신청 시 본 약관을 읽고 “동의합니다”를
        클릭하면 본 약관에 동의하는 것으로 간주됩니다.
        <br />
        ② 이용계약은 플램의 이용희망자의 본 약관에 동의를 한 후 플램이
        승낙함으로써 성립합니다.
        <br />
③ 이용계약은 ID단위로 체결하며, 체결 단위는 하나의 ID 이상이어야
        합니다.
      </List>
      <Title>제 6 조 (이용계약의 신청)</Title>
      <List>
        ① 서비스를 이용하고자 하는 자는 회원으로 가입하기 위해
        개인정보보호지침과 본 약관 등의 동의를 완료하여야 합니다.
        <br />
        ② 서비스를 이용하고자 하는 자는 플램을 통해 플램의 이용신청양식에 따라
        요구하는 사항을 기록하여야 합니다.
        <br />
③ 회원가입 이후 서비스를 제공받을 의사가 없는 등의 사유가 있는
        경우에는 해지(탈퇴) 할 수 있습니다.
      </List>
      <Title>제 7 조 (리워드의 적립 및 사용 등)</Title>
      <List>
        ① 회사는 회원의 서비스 이용에 따라 회원에게 리워드를 제공할 수 있다.
        <br />
        ② 회사가 회원에게 제공하는 리워드 적립 경로는 다음과 같다. 단 리워드
        적립 경로는 회사의 사정에 따라 달라질 수 있다.
        <br />
        {' '}
1. “리워드 음감회”에 정상적으로 참여한 경우
        <br />
        {' '}
2. “리워드 음감회” 추가 적립 조건을 달성할 경우
        <br />
        {' '}
3. 추천인 이벤트 조건을 달성할 경우
        <br />
        4. 기타 회사가 설정한 이벤트를 수행하거나 또는 당첨된 경우 ③ 회사는
        서비스의 효율적 이용 및 운영을 위해 사전 공지 후 제공하는 리워드의 일부
        또는 전부를 조정할 수 있으며, 리워드는 회사의 정책 및 운영상 필요에 따라
        수정, 중단, 변경 및 소멸될 수 있다.
        <br />
        ④ 회원이 서비스를 탈퇴하거나 자격이 상실되는 경우에는 리워드는 자동
        소멸된다.
        <br />
        ⑤ 회원은 일정액 이상 적립된 리워드를 이용하여 서비스 내에서 회사가
        지정/제공하는 방법을 통해 제휴된 다양한 혜택을 이용할 수 있으며,
        리워드에 따라 발생하는 제세공과금은 회사가 부담한다. 이때 플램 회원은
        제세공과금 납부를 위한 회사의 협조요청에 적극적으로 응해야 하며, 이는
        주민등록번호 제공 등 개인정보 제공 등의 내용을 포함한다. 이 과정에서
        회사의 요청에 응하지 않을 경우, 리워드를 통해 재화를 구매할 수 없다
        <br />
        ⑥ 리워드는 제휴사의 상품, 쿠폰 또는 포인트 등의 혜택으로 전환하는데
        필요한 기프티콘, 교환권, 쿠폰 등의 혜택은 회사가 회원의 모바일기기 또는
        플램 앱을 통해 해당 바코드를 전송함으로써 적법하게 이행된 것으로 본다.
        <br />
        ⑦ 리워드를 제휴사의 상품, 쿠폰 또는 포인트 등 혜택으로 전환하는 경우 일
        전환 횟수는 5회로 제한되며, 리워드로의 재전환, 환불, 취소 등이 불가하다.
        또, 모바일 상품권, 기프티콘, 교환권, 쿠폰 등의 혜택은 환불/재발행 및
        유효기간 연장이 불가하다.
        <br />
        ⑧ 제휴사가 제공한 혜택의 이용과 관련한 분쟁의 책임 소재가 제휴사에 있을
        경우, 회사는 책임을 부담하지 않는다
        <br />
        ⑨ 회사의 귀책사유가 아닌 이유로 회사와 제휴사 간의 거래과정에서 결제
        문제가 발생하거나, 제휴사의 파산, 부도 등으로 지급이 불가능한 경우에는
        기 부여된 제휴사 상품, 쿠폰 또는 소득이 취소될 수 있으며, 이에 대해
        회사는 책임을 부담하지 않는다.
        <br />
        ⑩ 회원이 적립한 리워드는 플램 앱 내에서 확인할 수 있다.
        <br />
        ⑪ 회사 서버에 기록된 리워드와 회원 서비스 앱 내에 표시된 리워드의 차이가
        나는 경우, 회사 서버에 기록된 리워드가 우선 적용되며, 회사는 회사 서버와
        이용자의 앱과의 비동기화로 인한 리워드 차이에 대해 보상하지 않는다.
        <br />
        ⑫ 리워드 소멸기한은 적립일자 기준으로 1년이며, 소멸기한이 지난 리워드는
        순차적으로 자동 소멸된다.
        <br />
        ⑬ 리워드의 적립 또는 사용이 없는 비활성화 상태가 90일 이상 계속된 회원의
        리워드는 소멸될 수 있다.
        <br />
⑭ 리워드 적립에 오류가 발생한 경우 회원은 오류 발생일로부터 30일
        내에 회사에 정정요구를 할 수 있으며, 회사는 정당한 요구임이 확인된 경우
        정정요구일로부터 30일 내에 정정할 수 있다.
      </List>
      <Title>제 8 조 (이용계약의 승낙과 유보)</Title>
      <List>
        ① 플램은 제6조에 따른 이용신청에 대하여 특별한 사정이 없는 한 접수
        순서대로 이용신청을 승낙합니다.
        <br />
        ② 플램은 다음 각 호에 해당하는 경우 이용신청에 대한 승낙을 제한할 수
        있고, 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
        <br />
        {' '}
1. 설비에 여유가 없는 경우
        <br />
        {' '}
2. 기술상에 지장이 있는 경우
        <br />
        {' '}
3. 기타 플램의 효율적인 운영 등을 위하여 필요하다고 인정되는 경우
        <br />
        {' '}
③ 플램은 다음 각 호에 해당하는 이용신청에 대하여는 이를 거절할 수
        있습니다.
        <br />
        1. 이용신청의 대상이 아닌 경우
        <br />
        {' '}
2. 다른 사람의 명의를 사용하여 이용신청을 한 경우
        <br />
        {' '}
3. 이용계약 신청서의 내용을 허위로 기재하였을 경우
        <br />
        {' '}
4. 저작권 보호, 사회의 안녕, 질서 혹은 미풍양속을 저해할 목적으로
        이용신청을 한 때
        <br />
        {' '}
5. 기타 플램에서 정한 이용신청 요건이 미비 된 경우
        <br />
④ 만14세 미만은 플램의 ‘리워드’ 적립 서비스 이용이 불가하며, 만
        14세 미만의 회원이 ‘리워드’ 적립 서비스를 통해 적립한 리워드는 회사가
        환수할 수 있습니다.
      </List>
      <Title>제 9 조 (이용계약의 해지)</Title>
      <List>
        ① 회원이 플램 이용계약을 해지하고자 하는 때에는 플램에서 해지신청을
        하여야 합니다.
        <br />
        ② 플램의 이용은 플램과 회원 간 사전에 이용계약 내지는 사용 승인한 목적
        내에서 사용이 제한되며, 플램은 회원이 다음 각 호에 해당하는 경우
        사전통지 없이 이용계약을 해지하거나 전부 또는 일부의 플램 제공을 제한할
        수 있습니다.
        <br />
        1. 타인의 회원번호를 사용한 경우
        <br />
        2. 다량의 정보를 전송 또는 수신하여 “플램”의 안정적 운영을 방해하는 경우
        <br />
        3. 정보통신설비의 오작동이나 정보 등의 파괴를 유발하는 컴퓨터 바이러스
        프로그램 등을 유포하는 경우
        <br />
        4. 플램을 통해 얻은 정보를 플램의 이용허락의 범위를 벋어나는 용도로 임의
        사용 또는 상업적으로 이용하는 경우
        <br />
        5. 기타 플램이 부적당하다고 판단하는 경우
        <br />
        6. 특별한 사유 없이 1년간 플램을 이용하지 아니한 경우
        <br />
③ 해지 처리된 회원의 정보는 서비스의 개선을 위한 자료로서 해지 후
        1년간 별도로 저장될 수 있습니다.
      </List>
      <Title>제 10 조 (회원 정보의 변경)</Title>
      <List>
        ① 회원은 개인정보관리화면을 통하여 본인의 개인정보를 열람하고 수정할 수
        있습니다.
        <br />
② 회원은 서비스 이용신청 시 기재한 사항이나 서비스 이용 과정에서
        회사에 제공한 정보가 변경되었을 경우 회원 정보를 변경하여야 하며,
        변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는 책임지지
        않습니다.
      </List>
      <Title>제 11 조 (개인정보 수집])</Title>
      <List>
        ① 회사는 적법하고 공정한 수단에 의하여 이용계약의 성립 및 이행에 필요한
        최소한의 개인정보를 수집합니다.
        <br />
        {' '}
② 회사는 개인정보의 수집 시 관련법규에 따라 개인정보처리방침에 그
        수집범위 및 목적을 사전 고지합니다.
        <br />
        {' '}
③ 회사는 서비스 화면에서 회사가 수집한 개인정보의 수집,이용 또는
        제공에 대한 동의를 철회할 수 있도록 필요한 조치를 취해야 합니다.
      </List>
      <Title>제 12 조 (개인정보보호 의무)</Title>
      <List>
        ① 회사는 개인정보보호법, 정보통신망법 등 관계 법령이 정하는 바에 따라
        회원의 개인정보를 보호하기 위해 노력합니다.
        <br />
② 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의
        개인정보처리방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된
        사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
      </List>
      <Title>제 13 조 (서비스 이용)</Title>
      <List>
        ① 회사는 회원에게 플램 서비스를 제공하는 것에 동의합니다. 서비스에는
        회사가 플램에서 제공하는 모든 제품, 기능, 앱, 서비스, 기술 및
        소프트웨어가 포함됩니다.
        <br />
        ② 회원은 회사에게 플램 서비스를 활용하면서 제공하거나 신규 생성되는 음악
        데이터들에 대하여 대해 플램이 가공 및 판매할 권한을 제공하는 것에
        동의합니다. 이 중 개인정보 데이터의 경우 플램 개인정보 이용약관에서
        정의한 조항에 따라 보호합니다.
        <br />
        ③ 회원은 플램 서비스에 제공한 음악 데이터의 홍보 목적의 전송을 하는 것에
        대하여 동의하고, 저작권 관련 분쟁을 제기할 경우 회사가 제13조 3항을
        근거로 시정을 요청할 수 있는 권리를 제공하는 것에 동의합니다.
        <br />
        ④ 회원은 회사에게 플램 서비스를 통해 음악 데이터를 제공할 이를 플램에서
        가공된 데이터를 플램에서 소유, 가공, 판매함에 동의하고, 회원이 플램
        서비스를 이용하거나 탈퇴할 시, 개인정보를 제외한 데이터를 플램에서
        소유하는 것에 동의합니다.
        <br />
⑤ 회원은 운영자에게 회사에서 제공하는 플램 서비스의 원활한 제공을
        위해 개인정보를 제외한 플램 데이터에 접근하여 관리하는 것에 동의합니다.
      </List>
      <Title>제 14 조 (회사의 의무)</Title>
      <List>
        ① 회사는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지
        않으며, 계속적이고 안정적으로 플램을 제공하기 위하여 최선을 다하여
        노력합니다.
        <br />
        ② 회사는 회원이 안전하게 플램을 이용할 수 있도록 개인정보 보호를 위해
        보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다.
        <br />
        ③ 회사는 서비스 제공과 관련하여 알고 있는 회원의 개인정보를 회원의 승낙
        없이 제3자에게 누설, 배포하지 않습니다. 다만, 관계법령에 의한 관계
        기관으로부터의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우에는
        그러하지 않습니다.
        <br />
        ④ 회사는 회원에게 제공하는 서비스를 계속적이고 안정적으로 제공하기
        위하여 설비에 장애가 생기거나 멸실 되었을 때 지체 없이 이를 수리 또는
        복구합니다. 다만, 천재지변이나 비상사태 등 부득이한 경우에는 서비스를
        일시 중단하거나 영구 중지할 수 있습니다.
        <br />
        ⑤ 회사는 회원로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는
        즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한 경우는 회원에게 그
        사유와 처리일정을 전자메일 등으로 통보하여야 합니다.
        <br />
⑥ 회사는 서비스 제공목적에 맞는 서비스 이용 여부를 확인하기 위하여
        상시적으로 모니터링을 실시합니다.
      </List>
      <Title>제 15 조 (회원의 의무)</Title>
      <List>
        ① 회원은 다음 행위를 하여서는 안 됩니다.
        <br />
        1. 서비스 이용 신청 또는 서비스 내용 변경 시 허위내용을 등록하는 행위
        <br />
        2. 타인의 정보나 명의를 도용하거나 부정하게 사용하는 행위
        <br />
        3. 다른 회원의 개인정보를 동의 없이 수집, 저장, 공개하는 행위
        <br />
        4. 회사가 게시한 정보를 변경하거나 제3자에게 제공하는 행위
        <br />
        5. 회사 또는 제3자의 저작권 등 지식재산권에 대한 침해 행위
        <br />
        6. 회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위
        <br />
        7. 외설 또는 폭력적인 메시지, 팩스, 음성, 메일, 기타 공서양속에 반하는
        정보를 서비스에 공개 또는 게시하는 행위
        <br />
        8. 회사의 동의 없이 본 약관의 목적 범위를 벗어나 영리를 목적으로
        서비스를 사용하는 행위
        <br />
        9. 타인의 의사에 반하는 내용을 지속적으로 전송하는 행위
        <br />
        10. 범죄행위를 목적으로 하거나 범죄행위를 교사하는 행위
        <br />
        11. 선량한 풍속 또는 기타 사회질서를 해치는 행위
        <br />
        12. 현행 법령, 회사가 제공하는 서비스에 정한 약관, 이용안내 및 서비스와
        관련하여 공지한 주의사항, 회사가 통지하는 사항, 기타 서비스 이용에 관한
        규정을 위반하는 행위
        <br />
        13. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위
        <br />
        14. 제3자에게 임의로 서비스를 임대하는 행위
        <br />
        15. 전기통신사업법 제84조의2 제1항을 위반하여 메시지 전송 시 발신번호를
        변작하는 등 거짓으로 표시하는 행위
        <br />
        16. 서비스를 불법스팸 전송에 이용하는 행위
        <br />
        17. 기타 불법적이거나 부당한 행위
        <br />
        ② 회원은 관계법, 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지한
        주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에
        방해되는 행위를 하여서는 안 됩니다.
        <br />
        ③ 회원은 정보통신망법의 광고성 정보 전송 시 의무사항 및 회사의
        이용약관을 준수하여야 합니다.
        <br />
④ 회원은 불법스팸 전송 등 불법행위를 하거나 전기통신사업법 등 관련
        법령을 준수하지 않아 발생하는 모든 민ㆍ형사상의 책임을 부담합니다.
      </List>
      <Title>제 16 조 (불만처리)</Title>
      <List>
        ① 회사는 개인정보와 관련하여 회원의 의견을 수렴하고 불만을 처리하기 위한
        절차를 마련하여야 합니다.
      </List>
      <Title>제 17 조 (서비스 제공)</Title>
      <List>
        ① 회사는 회원에게 제공하는 서비스를 홈페이지에 게시합니다.
        <br />
        ② 서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 단, 회사는
        서비스 제공에 필요한 경우 정기점검을 실시할 수 있으며 정기점검시간은
        회사가 사전에 통지한 바에 따릅니다.
        <br />
        ③ 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을
        별도로 지정할 수 있습니다. 다만 이러한 경우에는 그 내용을 사전에
        공지합니다.
        <br />
        ④ 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나
        중지할 수 있습니다.
        <br />
        1. 서비스 설비의 보수 등 공사로 인한 부득이한 경우
        <br />
        2. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스
        이용에 지장이 있는 경우
        <br />
        3. 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우
        <br />
        4. 기타 서비스를 유지할 수 없는 중대한 사유가 발생한 경우
        <br />
        ⑤ 본 조 제4항의 경우 회사는 제10조에서 정한 방법으로 회원에게
        통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우
        사후에 통지할 수 있습니다.
        <br />
⑥ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며
        정기점검시간은 플램 제공 화면에 공지한 바에 따릅니다.
      </List>
      <Title>제 18 조 (광고 게재)</Title>
      <List>
        ① 회사는 서비스의 운용과 관련하여 서비스 화면, 홈페이지 등에 광고 등을
        게재할 수 있습니다.
        <br />
② 회사는 서비스에 게재되어 있는 광고주의 판촉 활동에 회원이
        참여하거나 교신 또는 거래의 결과로서 발생하는 모든 손실 또는 손해에 대해
        책임을 지지 않습니다.
      </List>
      <Title>제 19 조 (서비스 이용의 제한 및 정지)</Title>
      <List>
        ① 회사는 주민등록법을 위반한 명의도용 및 결제도용, 저작권법을 위반한
        불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법통신, 해킹,
        악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한
        경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 서비스
        이용정지 시 서비스 내의 혜택 및 권리 등도 모두 소멸되며 회사는 이에 대해
        별도로 보상하지 않습니다.
        <br />
        ② 회사는 다음 각 호에 해당하는 경우에 회원의 서비스 이용을 제한할 수
        있습니다.
        <br />
        1. 회원의 이름 등 회원 정보가 정확하지 않은 경우
        <br />
        2. 다른 회원 또는 제3자의 지식재산권을 침해하거나 명예를 손상시킨 경우
        <br />
        3. 방송통신심의위원회의 시정요구가 있거나 불법선거운동과 관련하여
        선거관리위원회의 유권해석을 받은 경우
        <br />
        4. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우
        <br />
        5. 서비스를 이용하여 얻은 정보를 이용고객의 개인적인 이용 이외에 복사,
        가공, 번역, 2차적 저작물 등을 제작하여 복제, 공연, 방송, 전시, 배포,
        출판 등에 사용하는 경우
        <br />
        6. 회원이 플램 서비스를 통해 분석하여 제공한 데이터를 다른 사업자에
        회사의 동의 없이 비정상적인 방법으로 제공하는 경우
        <br />
        7. 기타 회사가 회원을 부적당하다고 판단한 경우
        <br />
        ④ 회사가 규정에 의하여 서비스를 제한 또는 정지하는 경우, 그 사실을
        회원에게 사전 통보하여야 합니다. 다만, 사전에 통보하는 것이 곤란할 경우
        이용정지 조치 후 통보할 수 있습니다.
        <br />
⑤ 회사는 12개월 이상 서비스를 이용하지 아니하는 회원의 개인정보를
        보호하기 위해 회원의 계정을 휴면계정으로 분류하고 서비스 이용계약을
        해지하거나 개인정보의 파기 또는 별도 분리보관 등 필요한 조치를 할 수
        있습니다.
        <br />
        ⑥ 회사는 휴면계정으로 분류되기 30일 전까지 개인정보가 파기되거나
        분리되어 저장되는 사실과 기간 만료일 및 해당 개인정보의 항목을 이메일,
        서면, 모사전송, 전화 또는 유사한 방법 중 하나의 방법으로 회원에게
        알리도록 합니다. (단, 회원의 정보가 정확하지 않은 경우 알림 대상에서
        제외될 수 있습니다.)
        <br />
        ⑦ 본 조에 의한 서비스 이용 정지 또는 이용 계약의 해지 시 데이터 삭제에
        대한 책임은 회원이 부담하며, 회사는 고의 또는 과실이 없는 한 데이터
        삭제에 따른 책임을 부담하지 않습니다.
        <br />
⑧ 회사가 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로
        서비스를 제공할 수 없게 되는 경우, 회사는 제10조에서 정한 방법으로
        회원에게 통지하고 서비스를 종료할 수 있습니다.
      </List>
      <Title>제 20 조 (이용계약 해지)</Title>
      <List>
        ① 회원은 이용계약을 해지 하고자 할 때 본인이 직접 서비스를 통하여
        신청하거나 전자우편, 전화 등의 방법을 통하여 회사에 신청하여야 합니다.
        <br />
        ② 회사는 규정에 의하여 해지신청이 접수되면 즉시 이용계약을 해지합니다.
        단, 별도의 채권·채무관계가 있을 경우에는 그러하지 아니합니다.
        <br />
        ③ 회사는 회원이 다음 각 호에 해당할 경우에는 회원의 동의 없이 이용계약을
        해지할 수 있으며 그 사실을 회원에게 통지합니다. 다만 회사가 긴급하게
        해지할 필요가 있다고 인정하는 경우나 회원의 귀책사유로 인하여 통지할 수
        없는 경우에는 지체 없이 사후 통지로 대체 할 수 있습니다.
        <br />
        1. 타인의 명의로 서비스 계약을 체결하였거나 서비스 계약 체결 시 제출한
        자료 및 정보가 허위 또는 누락 되었음이 확인된 경우
        <br />
        2. 회원이 본 약관을 위반하고 일정 기간 이내에 위반 내용을 해소하지 않는
        경우
        <br />
        3. 회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나 제3자에게
        임의로 서비스를 임대한 경우
        <br />
        4. 방송통신위원회 또는 한국인터넷진흥원 등이 불법스팸의 전송사실을
        확인하여 회사에게 서비스 계약 해지를 요청하는 경우 확인
        <br />
④ 회원 또는 회사가 계약을 해지할 경우 관련법 및 개인정보처리방침에
        따라 회사가 회원 정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든
        데이터는 소멸될 수 있으며, 소멸된 데이터는 어떤 경우에도 회원에게
        반환되지 않습니다.
      </List>
      <Title>제 21 조 (지식재산권)</Title>
      <List>
        ① 서비스와 서비스를 통해 분석되어 생성된 개인정보 외의 지식재산권은
        서비스를 제공한 회사에 귀속됩니다.
        <br />
        ② 회원이 플램 서비스를 이용하는 과정에서 작성한 게시물에 대한 저작권을
        포함한 일체 권리 및 책임은 별도의 의사표시가 없는 한 해당 회원에게
        귀속됩니다. 단, 회사는 회원이 등록한 게시물을 회원의 별도 허락이
        없더라도 서비스의 운영, 전시, 전송, 배포, 홍보의 목적으로 공정한 관행에
        합치되게 합리적인 범위 내에서 다음과 같이 사용할 수 있습니다.
        <br />
        1.서비스 내에서의 이용으로, 회원의 게시물의 복제, 수정, 개조, 전시,
        전송, 배포 할 수 있으며 저작물성을 해치지 않는 범위 내에서 편집 이용할
        수 있습니다.
        <br />
        2. 온라인 및 오프라인 미디어, SNS(Social Network Service), 통신사에서
        이용할 수 있도록, 회원의 게시물 내용을 제공, 복제, 수정, 개조, 전시,
        전송, 배포할 수 있으며, 저작물성을 해치지 않는 범위 내에서 편집 이용할
        수 있습니다.
        <br />
        ③ 회원이 작성한 서비스 내 게시물은 이용자 선택 또는 회사 정책에 따라
        서비스 탈퇴 시 모두 삭제될 수 있습니다.
        <br />
        ④ 회사가 제공하는 각종 서비스에 대한 저작권을 포함한 일체의 권리는 아래
        각 호를 포함하여 모두 회사에 귀속됩니다.
        <br />
        1. 서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다.
        <br />
        2. 회사가 제공하는 서비스의 디자인, 텍스트, 스크립트(Script), 그래픽 등
        회사가 제공하는 서비스에 관련된 모든 상표, 서비스 마크, 로고 등에 관한
        저작권, 기타 지적 재산권은 관련법령에 기하여 회사가 보유하고 있거나
        회사에게 소유권 또는 사용권이 있습니다. 단, 회원의 게시물과 제휴사와의
        제휴 계약에 따라 제공된 저작물 등은 제외합니다.
        <br />
        3. 회사는 회원에게 회사가 정한 이용조건에 따라 서비스를 이용할 수 있는
        권한, 게시물 작성 권한만을 부여하며, 회원은 이를 양도, 판매, 담보제공
        등의 처분 행위를 할 수 없습니다. 회원은 본 이용약관으로 인하여 서비스를
        소유하거나 서비스에 관한 저작권을 보유하게 되는 것이 아니라, 회사로부터
        서비스 이용 권한과 게시물 작성 권한만 허락받게 되는 바, 회원은 플램 앱
        내의 모든 컨텐츠 등을 이용자의 영리적 목적 등으로 사용할 수 없습니다.
        <br />
        4. 회원은 명시적으로 허락된 내용을 제외하고는 서비스를 통해 얻어지는
        서비스에 대한 정보, 다른 이용자들의 상태정보 등을 영리 또는 비영리적
        목적으로 사용, 복사(복제 포함, 이하 동일), 유통(송신, 출판, 배포, 방송
        등 포함, 이하 동일), 제3자에게 이용하도록 할 수 없으며, 회사가 만든
        텍스트, 스크립트, 그래픽 등을 복사하거나 유통할 수 없고, 제3자에게
        이용하도록 하여서는 안됩니다.
        <br />
        5. 회원은 회사의 명백한 서면 허가를 받은 경우를 제외하고는 서비스 또는
        이에 포함된 소프트웨어와 관련된 파생물 제작, 역파일, 소스 코드의 추출을
        시도할 수 없습니다.
      </List>
      <Title>제 22 조 (손해배상)</Title>
      <List>
        ① 회원이 본 약관의 규정을 위반 또는 부당한 행위로 인하여 회사에 손해가
        발생되는 경우 이 약관을 위반한 회원은 회사에 발생하는 모든 손해를 배상
        하여야 한다.
        <br />
② 회원이 서비스를 이용함에 있어 행한 불법행위나 본 약관 위반행위로
        인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을
        비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로
        회사를 면책 시켜야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로
        인하여 회사에 발생한 모든 손해를 배상하여야 한다.
      </List>
      <Title>제 23 조 (면책)</Title>
      <List>
        ① 회사는 다음 각 호의 경우로 플램을 제공할 수 없는 경우 이로 인하여 회원
        또는 제3자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
        <br />
        1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
        <br />
        2. 서비스의 효율적인 제공을 위한 시스템 개선, 장비 증설 등 계획된 플램
        중지 일정을 사전에 공지한 경우
        <br />
        3. 회원의 귀책사유로 플램 이용에 장애가 있는 경우
        <br />
        4. 회사의 고의 과실이 없는 사유로 인한 경우
        <br />
        5. 회사에게 회선, 통신망, 전용선을 제공하고 있는 이동통신사 또는
        부가통신사업자 측의 장애·귀책사유로 인한 플램의 불완전 또는 불능으로
        회원 또는 제3자에게 야기된 손해
        <br />
        6. 회원이 본 서비스에 제공한 정보, 자료, 사실 등이 부정확하여 본
        서비스를 원활히 제공할 수 없는 경우
        <br />
        ② 회사는 회원이 서비스를 통해 얻은 정보 또는 자료 등으로 인해 발생한
        손해와 서비스를 이용하거나 이용할 것으로부터 발생하거나 기대하는 손익
        등에 대하여 책임을 면합니다.
        <br />
        ③ 회사는 서비스 이용의 장애로 인하여 발생한 회원의 부가적, 영업적인
        손해에 대해서는 책임을 지지 않습니다.
        <br />
        ④ 회사는 회원이 게시 또는 전송한 데이터의 내용에 대해서는 책임을
        면합니다.
        <br />
        ⑤ 회사는 무료로 제공하는 서비스 이용과 관련하여 관련법에 특별한 규정이
        없는 한 책임을 지지 않습니다.
        <br />
        ⑥ 회사는 회원이 서비스를 통하여 전송한 메시지의 내용에 대해서는 그
        정확성, 신뢰성, 시기적절성 등을 보장하지 않으며 해당 내용이 관련 법령을
        위반하거나 제3자의 권리를 침해하는 경우 이에 대한 책임을 지지 않습니다.
        <br />
        ⑦ 회사는 회원의 서비스 이용을 정지 또는 제한하는 경우, 이용의 제한으로
        발생할 수 있는 회원의 손해 등에 대해서는 책임이 면제됩니다.
        <br />
        ⑧ 회사는 회원이 단말기 번호, 증명서류 등을 위조, 변조, 무단사용 또는
        오기입함으로써 회원 또는 타인에게 발생하는 손해에 대하여 보상 또는
        배상할 책임이 없습니다.
        <br />
        ⑨ 회사는 회원 확인을 위해 휴대폰본인확인 등을 수행할 수 있으나 회원이
        제공한 회원 본인의 정보에 대한 정확성, 진위, 유효성을 보증하거나
        담보하지는 않습니다. 회사는 회원이 제공한 정보의 오류로 인해 발생한
        손해에 대하여 보상 또는 배상할 책임이 없습니다.
        <br />
⑩ 회사는 회원에게 직접 또는 제휴사를 대행하여 상품 등을
        제공하거나, 회원을 대리하여 제휴사로부터 상품 등을 구매하지 않으며, 본
        서비스 내에서 리워드를 이용한 상품 이용 등에 있어 회원과 제휴사의 과실로
        발생한 손해에 대하여 책임이 없습니다.
      </List>
      <Title>제 24 조 (분쟁조정)</Title>
      <List>
        ① 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
        <br />
        ② 서비스 이용과 관련하여 회사와 회원 간에 발생한 분쟁에 대해서는
        민사소송법상의 주소지를 관할하는 법원을 합의관할로 합니다.
        <br />
③ 해외에 주소나 거소가 있는 회원의 경우 회사와 회원간 발생한
        분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을
        관할법원으로 합니다.
      </List>
      <Title>* 부 칙 *</Title>
      <List>
        1. 본 약관은 2024년 02월 01일부터 적용됩니다.
        <br />
        <br />
        플램 이용약관 시행일자 : 2024년 02월 01일
        <br />
        플램 이용약관 공고일자 : 2024년 02월 01일
      </List>
    </Content>
  )
}

export default TermsOfUseInfo
